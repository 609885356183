<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
        <md-content style="width: 1200px;">
            <div class="page-content" style="height: 95vh; overflow: auto; width: 100%;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                                <md-button v-shortkey="['ctrl', 'i']" @shortkey="close()" @click="close()" class="md-raised">Đóng</md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body" style="margin-bottom: 20px;">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box" style="margin-top:10px;">
                                        <div class="group-title">THÔNG TIN CHUNG</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                                        <label for="invoiceCode">Số quyết toán</label>
                                                        <md-input name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                                        <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập số quyết toán</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-datepicker v-model="entity.invoiceDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.invoiceDate.$error }">
                                                        <label>Ngày lập</label>
                                                        <span class="md-error" v-if="submitted && !$v.entity.invoiceDate.required">Vui lòng chọn ngày tạo</span>
                                                    </md-datepicker>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                                            <label>Nhân viên phụ trách</label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openStaff()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                                        <label for="branchId">Chi nhánh</label>
                                                        <md-select v-model="entity.branchId" name="branchId" id="branchId">
                                                            <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                                        </md-select>
                                                        <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-8 m-8 c-12">
                                                    <md-field>
                                                        <label for="reason">Nội dung</label>
                                                        <md-input name="reason" v-model="entity.note"></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="form-amount">
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng quyết toán</label>
                                                            <span>{{formatNumber(entity.payFee + entity.payOnBehalf + entity.paySalary + entity.payVetc)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng chi phí</label>
                                                            <span>{{formatNumber(entity.payFee)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng chi hộ</label>
                                                            <span>{{formatNumber(entity.payOnBehalf)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng lương lái xe</label>
                                                            <span>{{formatNumber(entity.paySalary)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng Vetc</label>
                                                            <span>{{formatNumber(entity.payVetc)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng phí dịch vụ</label>
                                                            <span>{{formatNumber(entity.payService)}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-tab">
                                <md-tabs>
                                    <md-tab id="tab-estimate" class="tab-content" md-label="Lệnh điều xe">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:50px;">#</th>
                                                                <th style="width:25%;">Số điều xe</th> 
                                                                <th style="width:15%;">Lái xe</th> 
                                                                <th style="width:10%;">Số xe</th> 
                                                                <th class="right" style="width:12%;">Dự toán</th>
                                                                <th class="right" style="width:12%;">Giải chi</th>
                                                                <th class="right" style="width:12%;">Quyết toán</th>
                                                                <th style="width:10%">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.jobList" :key="'jobList-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.job.jobCode" class="form-control" type="text">
                                                                        <md-button @click="openJobList(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm số điều xe</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    {{ item.job.driver.fullName }}
                                                                </td> 
                                                                <td class="center">
                                                                    {{ item.job.vehicle.licensePlate }}
                                                                </td> 
                                                                <td class="right">
                                                                    {{ formatNumber(item.totalEstimate) + ' VNĐ'}}
                                                                </td> 
                                                                <td class="right">
                                                                    {{ formatNumber(item.totalExplain) + ' VNĐ'}}
                                                                </td> 
                                                                <td class="right">
                                                                    {{ formatNumber(item.totalInvoice) + ' VNĐ'}}
                                                                </td>
                                                                <td class="grid-action">
                                                                    <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td>
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết quyết toán">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="list" style="overflow-x: auto; width: 100%;">
                                                        <table class="data-table-2" style="width: 1870px;"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th rowspan="2" style="width:50px;">#</th> 
                                                                    <th rowspan="2" class="left" style="width:200px;">Mã điều xe</th>
                                                                    <th rowspan="2" class="left" style="width:150px;">Số cont/Mã kiện</th> 
                                                                    <th rowspan="2" class="left" style="width:150px;">Hàng hóa</th> 
                                                                    <th rowspan="2" class="left" style="width:200px;">Tên phí</th>
                                                                    <th rowspan="2" class="left" style="width:120px;">Loại phí</th>
                                                                    <th rowspan="2" class="center" style="width:120px; background-color: #96C9F4;">Dự toán</th>
                                                                    <th colspan="5" class="center" style="width:500px; background-color: #ADD899;">Giải chi</th>
                                                                    <th colspan="4" class="center" style="width:500px; background-color: #F5DAD2;">Quyết toán</th>
                                                                </tr>
                                                                <tr> 
                                                                    <th class="right" style="width:100px; background-color: #ADD899;">Đơn giá</th>
                                                                    <th class="center" style="width:100px; background-color: #ADD899;">VAT</th>
                                                                    <th class="right" style="width:100px; background-color: #ADD899;">Thành tiền</th>
                                                                    <th class="center" style="width:100px; background-color: #ADD899;">Số hóa đơn</th>
                                                                    <th class="center" style="width:100px; background-color: #ADD899;">Ngày hóa đơn</th>
                                                                    <th class="center" style="width:100px; background-color: #F5DAD2;">Đơn giá</th>
                                                                    <th class="center" style="width:100px; background-color: #F5DAD2;">VAT</th>
                                                                    <th class="center" style="width:100px; background-color: #F5DAD2;">Thành tiền</th>
                                                                    <th class="center" style="width:200px; background-color: #F5DAD2;">Ghi chú</th>
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loadding == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loadding == false && entity.details.length > 0"> 
                                                                <tr v-for="(item, index) in entity.details" :key="'detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="left">
                                                                        <span v-if="item.job !== null">{{ item.job.jobCode }}</span>
                                                                    </td>
                                                                    <td class="left">
                                                                        {{ item.orderDetail.serial }}
                                                                    </td>
                                                                    <td class="left">
                                                                        {{ item.orderDetail.goods.goodsName }}
                                                                    </td>
                                                                    <td class="left">
                                                                        {{ item.fee.feeName }}
                                                                    </td>
                                                                    <td class="left">
                                                                        {{ getFeeType(item.type) }}
                                                                    </td>
                                                                    <td class="right">
                                                                        {{ formatNumber(item.estimateAmount) }}
                                                                    </td>
                                                                    <td class="right">
                                                                        {{ formatNumber(item.explainPrice) }}
                                                                    </td>
                                                                    <td class="right">
                                                                        {{ formatNumber(item.explainVat) }}
                                                                    </td>
                                                                    <td class="right">
                                                                        {{ formatNumber(item.explainAmount) }}
                                                                    </td>
                                                                    <td class="left">
                                                                        {{ item.invoiceNumber }}
                                                                    </td>
                                                                    <td class="left">
                                                                        {{ item.invoiceDate }}
                                                                    </td>
                                                                    <td class="right">
                                                                        <div class="group-control">
                                                                            <number @change="calSum(item)" v-model="item.invoicePrice" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                    <td class="right">
                                                                        <div class="group-control">
                                                                            <number @change="calSum(item)" v-model="item.invoiceVat" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                    <td class="right">
                                                                        <div class="group-control">
                                                                            <number :disabled="true" v-model="item.invoiceAmount" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.invoiceNote" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-fuel" class="tab-content" md-label="Định mức dầu">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar"></div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:15%;">Mã lệnh điều xe</th> 
                                                                <th style="width:120px;">Hành trình</th>
                                                                <th style="width:20%;">Điểm đi</th> 
                                                                <th style="width:20%;">Điểm đến</th> 
                                                                <th style="width:10%;">Số Km</th>
                                                                <th style="width:10%;">Định mức dầu(lít)</th>
                                                                <th style="width:10%;">Đơn giá</th>
                                                                <th style="width:10%;">Thành tiền</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.routes" :key="'request-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="left">
                                                                    {{ item.job.jobCode }}
                                                                </td>
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="left">
                                                                    <span v-if="item.start !== null">{{ item.start.locationName }}</span>
                                                                </td>
                                                                <td class="left">
                                                                    <span v-if="item.end !== null">{{ item.end.locationName }}</span>
                                                                </td> 
                                                                <td class="right">
                                                                    {{ item.km }}
                                                                </td>
                                                                <td class="right">
                                                                    {{ item.fuel }}
                                                                </td> 
                                                                <td class="right">
                                                                    {{ formatNumber(item.price) }}
                                                                </td>
                                                                <td class="right">
                                                                    {{  formatNumber(item.amount) }}
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:20%;">Bộ phận/phòng</th> 
                                                                <th style="width:20%;">Người phê duyệt</th> 
                                                                <th style="width:20%;">Tiêu đề</th>
                                                                <th style="width:20%;">Ghi chú</th> 
                                                                <th style="width:10%;">Thứ tự</th> 
                                                                <th style="width:150px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.approveList" :key="'approve-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.dept.name" class="form-control" type="text">
                                                                        <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                        <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.title" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.note" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeApprove"/>
                <approveList ref="approveList" @close="closeApprove"/>
                <deptList ref="deptList" @close="closeDept"/>
                <jobList ref="jobList" @close="closeJob"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import jobInvoiceService from '@/api/jobInvoiceService';
    import jobService from '@/api/jobService';
    import approveSettingService from '@/api/approveSettingService';
    import staffService from '@/api/staffService';
    import branchService from '../../../api/branchService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import staffList from '@/components/popup/_StaffList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import jobList from '@/components/popup/_JobListForInvoice.vue';
    import approveList from '@/components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            jobList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật quyết toán lái xe'
        },
        data() {
            return {
                title: '',
                id: 0,
                tab: 0,
                showDialog: false,
                submitted: false,
                loadding: false,
                entity: { id: 0, branchId: 0, orderType: 1, status: 0, details: [], jobList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, invoiceCode: '', invoiceDate: common.dateNow, pay: 0, payFee: 0, payOnBehalf: 0, paySalary: 0, payService: 0, payVetc: 0 },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                job: { id: 'id_' + common.getFakeId(), jobId: 0, job: { jobCode: '', jobDate: '' }, totalEstimate: 0, totalExplain: 0, totalInvoice: 0},
                vat: common.vatF,
                currency: common.currencyF,
                staffName: '',
                staffs: [],
                catFee: 0,
                orderType: 1,
                branchs: [],
            }
        },
        created(){
            this.getBranchs();
            this.orderType = this.$route.query.orderType == undefined ? 1 :  this.$route.query.orderType;
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            close(){
                this.showDialog = false;
            },

            async openEdit(id){
                this.id = id;
                this.entity.pay = 0;
                this.entity.payFee = 0;
                this.entity.payOnBehalf = 0;
                this.entity.paySalary = 0;
                this.entity.payService = 0;
                this.entity.payVetc = 0;
                this.title = 'Cập nhật quyết toán lái xe';
                this.getById();
                this.showDialog = true;
            },

            async open(list){
                this.title = 'Thêm mới quyết toán lái xe';
                this.getByAccount();
                this.entity.approveList.push(this.approve);
                this.getApproveSetting();
                this.entity.pay = 0;
                this.entity.payFee = 0;
                this.entity.payOnBehalf = 0;
                this.entity.paySalary = 0;
                this.entity.payService = 0;
                this.entity.payVetc = 0;
                this.entity.jobList = [];
                this.entity.routes = [];
                for(let i = 0; i < list.length; i++){
                    this.addRow();
                    this.selectedId = this.entity.jobList[this.entity.jobList.length - 1].id;
                    this.tab = this.selectedId;

                    let res = await jobService.getById(list[i].id);
                    let job = res.data;
                    this.addJob(job);

                    job.routes.map((route) => {
                        let routeItem = {
                            id: route.id,
                            job: { jobCode: job.jobCode },
                            start: route.start,
                            end: route.end,
                            km: route.km,
                            fuel: route.fuel,
                            price: route.price,
                            amount: route.amount
                        };
                        this.entity.routes.push(routeItem)
                    })
                }
                this.showDialog = true;
            },

            addJob(item){
                console.log(item)
                const selected = this.entity.jobList.findIndex(x => x.id == this.selectedId);
                this.entity.jobList[selected].job = item;
                this.entity.jobList[selected].jobId = item.id;
                this.entity.jobList[selected].totalEstimate = item.totalEst;
                this.entity.jobList[selected].totalExplain = item.totalExplain;
                this.entity.jobList[selected].totalInvoice = item.totalExplain;

                this.selectedId = '';
                for(var i = 0; i < item.details.length; i ++){
                    const estList = item.details[i].estimate.details;
                    estList.map((obj) => {
                        if(obj.estimateAmount > 0 || obj.explainPrice > 0){
                            obj.invoicePrice = obj.explainPrice;
                            obj.invoiceVat = obj.explainVat;
                            obj.invoiceAmount = obj.explainAmount;
                            obj.invoiceNote = obj.explainNote;
                            let newObject = {
                                job: item,
                                estimate: obj,
                                orderDetail: item.details[i].orderDetail
                            };
                            let detail = Object.assign({}, obj, newObject);
                            this.entity.details.push(detail);
                        }
                    })
                }
                this.calTotal();
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            calTotal(){
                this.entity.pay = 0;
                this.entity.payFee = 0;
                this.entity.payOnBehalf = 0;
                this.entity.paySalary = 0;
                this.entity.payService = 0;
                this.entity.payVetc = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    let item = this.entity.details[i];
                    if(item.type == 1){
                        this.entity.payFee = this.entity.payFee + parseFloat(item.invoiceAmount);
                    }
                    else if(item.type == 2){
                        this.entity.payOnBehalf = this.entity.payOnBehalf + parseFloat(item.invoiceAmount);
                    }
                    else if(item.type == 3){
                        this.entity.payService = this.entity.payService + parseFloat(item.invoiceAmount);
                    }
                    else if(item.type == 4){
                        this.entity.paySalary = this.entity.paySalary + parseFloat(item.invoiceAmount);
                    }
                    else if(item.type == 6){
                        this.entity.payVetc = this.entity.payVetc + parseFloat(item.invoiceAmount);
                    }
                }
                this.entity.pay = this.entity.payFee + this.entity.payOnBehalf + this.entity.paySalary + this.entity.payService +  this.entity.payVetc;
            },

            calSum(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                let price = parseFloat(this.entity.details[selected].invoicePrice);
                let vat =  parseFloat(this.entity.details[selected].invoiceVat) / 100;
                this.entity.details[selected].invoiceAmount = price * (1 + vat);
                this.updateAmoutJob(item);
                this.calTotal();
                
            },

            updateAmoutJob(item){
                const selected = this.entity.jobList.findIndex(x => x.jobId == item.job.id);
                let invoice = 0;
                for(var i = 0; i < this.entity.details.length; i ++){
                    if(this.entity.details[i].job.id == item.job.id){
                        invoice = invoice + parseFloat(this.entity.details[i].invoiceAmount);
                    }
                }
                this.entity.jobList[selected].totalInvoice = invoice;
            },

            addRow(){
                const item = { 
                    id: 'id_' + common.getFakeId(), 
                    job: { jobCode: '', jobDate: '', driver: { fullName: '' }, vehicle: { licensePlate: '' }, staff: { fullName: '' } },
                    jobId: 0,
                    totalEstimate: 0, 
                    totalExplain: 0, 
                    totalInvoice: 0
                };
                this.entity.jobList.push(item);
            },

            //Estimate Request
            delRow(item){
                const index = this.entity.jobList.findIndex(x => x.id == item.id);
                this.entity.jobList.splice(index, 1);
                for(var i = 0; i < this.entity.details.length; i ++){
                    let jobId = this.entity.details[i].jobId;
                    
                    if(item.jobId === jobId){
                        this.entity.details = this.entity.details.filter(obj => !(obj.jobId == item.jobId));
                    }
                }

                this.calTotal();
            },

            closeJob(item){
                this.addJob(item);
                this.$refs.jobList.close();
            },

            uuidv4(id) {
                let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
                );

                return uuid + "-" + id;
            },

            openJobList(id){
                this.selectedId = id;
                this.$refs.jobList.open(this.entity.branchId);
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            getCode(staffId){
                this.setLoading(true);
                jobInvoiceService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.invoiceCode = response.data.code;
                        this.entity.invoiceNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.customsjob).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairjob), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.jobList.length > 0){
                    this.entity.jobList = this.entity.jobList.filter(item => !(item.jobId == 0));
                    this.entity.jobList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.jobList.length <= 0){
                    messageBox.showWarning("Vui lòng nhập lệnh điều xe");
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập chi tiết quyết toán");
                    return;
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                jobInvoiceService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                jobInvoiceService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.entity.branchId = response.data.department.branchId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                jobInvoiceService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/job-invoice?orderType=' + this.orderType);
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                invoiceCode: { required },
                invoiceDate: { required },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                }
            }
        }
    })
</script>


<style scoped>
    .btn-add {
        background-color: #007bff;
        padding: 7px 10px;
        color: #FFF !important;
        border-radius: 3px;
    }
    .btn-add i {
        color: #FFF !important;
    }
    .job-list {
        border: 1px solid #ccc;
    }
    .box-item:first-child {
        padding-top: 5px;
    }
    .box-item {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0;
    }
    .box-item > label {
        width: 130px;
    }
</style>